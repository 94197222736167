import apiEndpoints from '@src/lib/apiEndpoints';

import apiRequest from './api';

export interface UpdateTutorialProps {
  phase_number: number;
  status: boolean;
}

export const updateTuts = async (data: UpdateTutorialProps) => {
  return apiRequest({
    method: 'PUT',
    data: data,
    url: `${apiEndpoints.UPDATE_TUTS}`,
  });
};
