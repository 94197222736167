import { lazy } from 'react';

import { AsaSubRoutes, RoleRoutes, Routes } from '@src/lib/constants';
import { RoutesConfigType } from '@src/lib/types';

const SignUp = lazy(() => import('@src/screens/newAsa/signUp'));
const Profile = lazy(() => import('@src/screens/Profile'));

const newAsaRoutes: Array<RoutesConfigType> = [
  {
    path: Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT,
    component: SignUp,
    layout: 'private',
    isBreadcrumb: false,
    isParentOnlyScreen: true,
  },
  {
    path: Routes.PROFILE,
    component: Profile,
    layout: 'private',
    isBreadcrumb: false,
    isParentOnlyScreen: true,
  },
];

export default newAsaRoutes;
