import apiEndpoints from '@src/lib/apiEndpoints';
import { Student } from '@src/Models/student';

import apiRequest from './api';
import { AsaAttendanceProps, GetTableFilterProps } from './type';

export const getStudent = (params: GetTableFilterProps): Promise<Student[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STUDENTS}`,
    config: { params },
  });
};

export const getMyStudentsAttendance = (
  params: Pick<GetTableFilterProps, 'activity_id' | 'program_id' | 'date' | 'search'>
): Promise<Student[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.MY_STUDENTS}`,
    config: { params },
  });
};

export const getStudentById = async (id: string): Promise<Student> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.STUDENTS}/${id}`,
  });
};

export const createAsaAttendance = async (data: AsaAttendanceProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.ASA_ATTENDANCE}`,
    data,
  });
};

export const getAsaAttendance = async (params: Pick<GetTableFilterProps, 'activity_id' | 'program_id' | 'date'>) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.ASA_ATTENDANCE}`,
    config: { params },
  });
};
