import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn, ScheduleActivitiesProps, ScheduleProps } from '@src/lib/types';

import apiRequest from './api';
import { GetTableFilterProps } from './type';

export const getSchedules = async (params: ScheduleProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SCHEDULE}`,
    config: { params },
  });
};

export const getSchedulesFilterList = async (params: ScheduleProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SCHEDULE_LIST}`,
    config: { params },
  });
};

export const getOffDays = async (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.OFF_DAYS}`,

    config: { params },
  });
};

export const updateOffDays = async (id: string, data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'PUT',
    data: data,
    url: `${apiEndpoints.OFF_DAYS}/${id}`,
  });
};

export const deleteOffDaysByID = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.OFF_DAYS}/${id}`,
  });
};

export const getOffDaysById = async (id: string) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.OFF_DAYS}/${id}`,
  });
};

export const createOffDays = async (data: FormControllerDataReturn) => {
  return apiRequest({
    method: 'POST',
    data: data,
    url: `${apiEndpoints.OFF_DAYS}`,
  });
};

export const getScheduleActivities = async (params: ScheduleActivitiesProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.SCHEDULE_ACTIVITIES}`,
    config: { params },
  });
};
