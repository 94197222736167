import { createContext, Dispatch, SetStateAction } from 'react';

import { Modes, ModeType } from '@src/lib/styles/theme';
import { Children } from '@src/Models/user';
export type SnackType = { type: 'success' | 'error'; message: string } | undefined;

export type SelectedChildrenProps = Children | { id: 'all' };

export type LoadingType = 'modal' | 'table' | false;

export interface WalkthroughData {
  phase: number;
  tourRunning: boolean;
  stepIndex: number;
}

export interface CartInfoProps {
  count: number;
  refetch: () => void;
}

interface GlobalUIContextProps {
  mode: ModeType;
  loading: LoadingType;
  setMode: (val: Modes) => void;
  setLoading: (val: LoadingType) => void;
  setSnackValue: (val: SnackType) => void;
  snackValue: SnackType;
  selectedChildren?: SelectedChildrenProps;
  setSelectedChildren?: (value: SelectedChildrenProps) => void;
  walkThrough: WalkthroughData;
  setWalkThrough?: Dispatch<SetStateAction<WalkthroughData>>;
  cartInfo?: CartInfoProps;
  setCartInfo?: Dispatch<SetStateAction<CartInfoProps | undefined>>;
}

export const GlobalUIContext = createContext<GlobalUIContextProps>({
  mode: Modes.LIGHT,
  loading: false,
  snackValue: undefined,
  walkThrough: {
    phase: 0,
    stepIndex: 0,
    tourRunning: false,
  },
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setSnackValue: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setMode: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setLoading: () => {},
});
