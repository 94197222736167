import { useContext, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';
import moment from 'moment';

import { Avatar, Box, Typography } from '@mui/material';
import { googleLogout } from '@react-oauth/google';

import WalkThrough from '@src/components/WalkThrough';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { getFullName } from '@src/lib/helper';
import { Children, StudentId } from '@src/Models/user';
import { StyledButton } from '@src/screens/newAsa/signUp/style';
import { getCookie } from '@src/utils/getCookie';

import FormValueLayout from './FormValueLayout';

const ProfileDrawerLayout = ({ childData }: { childData: Children | StudentId }) => {
  const navigate = useNavigate();
  const { userDetail, handleSetUserDetails, removeAuthToken } = useContext(AuthContext);
  const inputRef = useRef<HTMLInputElement>(null);
  const { handleUpdateNickname } = useAxiosApi();
  const selectedProfileId = useSearchParams()[0].get('selectedProfileId');
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();

  const handleUpdateClick = async () => {
    if (!selectedProfileId || !userDetail?.id) return console.error('Id not specified');

    try {
      await handleUpdateNickname(userDetail.id, {
        children: { student_id: selectedProfileId, nick_name: inputRef.current?.value || '' },
      });

      const tokenValue = getCookie('access_token');
      await handleSetUserDetails(tokenValue || '');

      const currentPath = window.location.pathname;
      navigate(currentPath);
    } catch (e) {
      console.error(e);
    }
  };

  const isStudent = userDetail?.role === 'student';

  const WalkThroughSteps = [
    {
      target: '#add-nickname-tour-target',
      heading: 'addNickname',
      content: 'addNicknameDescription',
      disableBeacon: true,
    },
  ];

  return (
    <WalkThrough phase={4} isSkip steps={WalkThroughSteps}>
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: '24px', flex: 1 }}>
        <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <Avatar
            alt='avatar'
            style={{
              background: styledTheme.colors.cantaloupe,
              color: styledTheme.colors.tangerine,
              fontSize: 16,
              width: 100,
              height: 100,
            }}
            src={childData?.image?.url}
          />
        </Box>
        <FormValueLayout heading={t('fullName')} value={getFullName(childData?.first_name, childData?.last_name)} />
        {isStudent ? (
          childData?.nick_name ? (
            <FormValueLayout heading='Nickname' value={childData?.nick_name} />
          ) : null
        ) : (
          <FormValueLayout
            heading={t('nickname')}
            placeholder={t('enterNickname')}
            optional
            value={childData?.nick_name}
            inputRef={inputRef}
            instructions={t('nicknameInfo')}
          />
        )}
        <FormValueLayout heading={t('idNumber')} value={childData?.student_number} />
        <FormValueLayout heading={t('grade')} value={childData?.grade_level} />
        <FormValueLayout
          heading={t('dateOfBirth')}
          value={moment(childData?.birth_date).format('DD MMM YY')}
          calendar
        />
        <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'end', flex: 1 }}>
          {isStudent ? (
            selectedProfileId === 'true' ? null : (
              <Box
                sx={{
                  p: '16px 12px',
                  bgcolor: styledTheme.colors.white,
                  borderRadius: '12px',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: '8px',
                  cursor: 'pointer',
                }}
                onClick={() => {
                  googleLogout();
                  removeAuthToken();
                }}
              >
                <img src='/icons/logout.svg' alt='logout' />
                <Typography sx={{ fontSize: '14px', fontWeight: '500', color: styledTheme.colors.midnightGray }}>
                  {t('logout')}
                </Typography>
              </Box>
            )
          ) : (
            <StyledButton variant='contained' onClick={handleUpdateClick} style={{ width: 'auto' }}>
              {t('updateProfile')}
            </StyledButton>
          )}
        </Box>
      </Box>
    </WalkThrough>
  );
};

export default ProfileDrawerLayout;
