import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';

import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import { Box, Skeleton } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import { googleLogout } from '@react-oauth/google';

import { ArrowDown } from '@src/components/Icons/ArrowDown';
import { GeneralInfoModal } from '@src/components/Modals/GeneralInfoModal';
import WalkThrough from '@src/components/WalkThrough';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { AsaSubRoutes, MerchandiseSubRoutes, RoleRoutes, RouteActions, Routes } from '@src/lib/constants';
import { DrawerItem, Language, LanguageType } from '@src/lib/types';
import ProfileModal from '@src/screens/Profile/ProfileModal';
import { getCookie } from '@src/utils/getCookie';

import {
  HeaderItem,
  HeaderRightItem,
  IconButtonHeaderRightItem,
  StyledLogo,
} from '../../../screens/newAsa/signUp/style';

import CustomMenu, { MenuOption } from './CustomMenu';

const DrawerList = [
  {
    key: 'asa',
    text: 'activities',
    url: '/icons/menu-board.svg',
    redirectionUrl: Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT,
  },
  {
    key: 'merchandise',
    text: 'merchandise',
    url: '/icons/shopping-bag.svg',
    redirectionUrl: Routes.MERCHANDISE + MerchandiseSubRoutes.DASHBOARD,
  },
];

const WebHeader = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { headers, userDetail, handleSetUserDetails, removeAuthToken } = useContext(AuthContext);
  const { handleSwitchUserRole } = useAxiosApi();
  const isHomeIcon = ['parent', 'student', 'staff_cum_parent'].includes(userDetail?.role || '');
  const { styledTheme } = useGetTheme();
  const currentModule = location.pathname.split('/')[1] as DrawerItem;
  const [isGeneralInfoModalOpen, setIsGeneralInfoModalOpen] = useState(false);

  const changeLanguage = (lang: string) => {
    i18n.changeLanguage(lang as LanguageType);
    localStorage.setItem('lang', lang);
  };

  const handleLogoClick = () => {
    if (isHomeIcon) navigate(Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT);
  };

  const settings: MenuOption[] = [
    {
      label: t('profile'),
      onClick: () => {
        if (userDetail?.role === 'student') {
          const currentPath = window.location.pathname;
          navigate(currentPath + `?selectedProfileId=true`);
          return;
        }

        navigate(Routes.PROFILE);
      },
      url: '/icons/profile.svg',
      key: 'profile',
    },
    ...(userDetail?.role === 'super_admin'
      ? [
          {
            key: '2fa-auth',
            label: `${userDetail?.['2fa'] ? 'Disable' : 'Enable'} 2FA`,
            onClick: () => {
              navigate(
                userDetail?.['2fa']
                  ? `${Routes.USERS}${Routes.TWO_FA}${RouteActions.DISABLE}`
                  : `${Routes.USERS}${Routes.TWO_FA}`
              );
            },
            icon: VpnKeyIcon,
          },
        ]
      : []),
    ...(userDetail?.ps_role === 'staff_cum_parent'
      ? [
          {
            label: `${t('switchTo')} ${userDetail?.role === 'parent' ? t('staff') : t('parent')}`,
            onClick: async () => {
              try {
                await handleSwitchUserRole({ data: { role: userDetail?.role === 'parent' ? 'staff' : 'parent' } });
                const tokenValue = getCookie('access_token');
                await handleSetUserDetails(tokenValue || '');

                if (userDetail?.role === 'parent') {
                  navigate(Routes.ASA + AsaSubRoutes.PROGRAMS);
                } else {
                  navigate(Routes.ASA + AsaSubRoutes.SCHEDULING + RoleRoutes.PARENT);
                }
              } catch (err) {
                console.error(err);
              }
            },
            icon: SwitchAccountIcon,
            key: 'switch',
          },
        ]
      : []),
    {
      label: t('logout'),
      onClick: () => {
        googleLogout();
        removeAuthToken();
      },
      url: '/icons/logout.svg',
      key: 'logout',
    },
  ];

  const LanguagesOptions = [
    { label: '🇺🇸 English', key: Language.EN, onClick: changeLanguage },
    { label: '🇫🇷 Français', key: Language.FR, onClick: changeLanguage },
    { label: '🇰🇷 한국인', key: Language.KO, onClick: changeLanguage },
    { label: '🇯🇵 日本語', key: Language.JA, onClick: changeLanguage },
  ];

  const WalkThroughSteps = [
    {
      target: '#web-header-tour-target',
      heading: 'selectModules',
      content: 'selectModulesDescription',
      disableBeacon: true,
    },
    {
      target: '#change-language-tour-target',
      heading: 'switchLanguage',
      content: 'switchLanguageDescription',
      disableBeacon: true,
    },
  ];

  return (
    <WalkThrough phase={1} steps={WalkThroughSteps}>
      <Box
        id='web-header-tour-target'
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          userSelect: 'none',
          bgcolor: styledTheme.colors.white,
          height: '64px',
          p: '12px 60px',
          borderBottom: `1px solid ${styledTheme.colors.mistGray}`,
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          <StyledLogo src='/logo.svg' alt='logo' onClick={handleLogoClick} />
          {!headers
            ? Array(4)
                .fill('')
                .map(() => <Skeleton variant='rectangular' width={70} height={30} />)
            : DrawerList.map(({ key, text, url, redirectionUrl }) => {
                if (!(headers === 'super_admin' || headers[key])) return null;

                return (
                  <HeaderItem
                    key={key}
                    $isActive={key === currentModule}
                    onClick={() => {
                      navigate(redirectionUrl);
                    }}
                  >
                    <img src={url} alt={text} />
                    <span style={{ marginTop: '2px' }}>{t(text)}</span>
                  </HeaderItem>
                );
              })}
        </Box>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '24px' }}>
          <IconButtonHeaderRightItem onClick={() => setIsGeneralInfoModalOpen(true)}>
            <img src='/icons/info-circle.svg' alt='Change Language' />
            <span style={{ marginTop: '2px' }}>{t('generalInfo')}</span>
          </IconButtonHeaderRightItem>
          <CustomMenu options={LanguagesOptions} menuID='translation-menu'>
            <HeaderRightItem $isNotPadding id='change-language-tour-target'>
              <img src='/icons/group.svg' alt='Change Language' />
              <span style={{ marginTop: '2px' }}>
                {LanguagesOptions.find(item => item.key === i18n.language)?.label}
              </span>
              <ArrowDown />
            </HeaderRightItem>
          </CustomMenu>
          <CustomMenu options={settings} menuID='profile-menu'>
            <HeaderRightItem $isNotPadding>
              <Avatar
                alt='avatar'
                src={userDetail?.parent_id?.image?.url}
                style={{ background: styledTheme.colors.black, color: styledTheme.colors.white }}
              >
                {!userDetail?.parent_id?.image?.url && (userDetail?.first_name?.charAt(0).toUpperCase() || 'A')}
              </Avatar>
              <span style={{ marginTop: '2px' }}>{userDetail?.first_name}</span>
              <ArrowDown />
            </HeaderRightItem>
          </CustomMenu>
        </Box>
        <GeneralInfoModal isOpen={isGeneralInfoModalOpen} handleClose={() => setIsGeneralInfoModalOpen(false)} />
        <ProfileModal />
      </Box>
    </WalkThrough>
  );
};

export default WebHeader;
