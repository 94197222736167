import { Suspense } from 'react';
import { BrowserRouter as Router, Navigate, Route, Routes } from 'react-router-dom';

import Loader from '@src/components/Loader';
import PrivateRoute from '@src/layouts/PrivateRoute';
import PublicRoute from '@src/layouts/PublicRoute';
import { RoutesConfigType } from '@src/lib/types';
import routesConfig from '@src/routes/routesConfig';

import RestrictedRoute from './layouts/RestrictedRoute';
import { RootRestrictedRoute } from './layouts/RootRestrictedRoute';
import { redirectionRoutes } from './routes/redirectionRoutes';

const App = () => {
  const renderLayout = (config: RoutesConfigType): JSX.Element => {
    const Component = config.component;

    switch (config.layout) {
      case 'private':
        return <Route key={config.path} path={config.path} element={<PrivateRoute {...config} />} />;
      case 'public':
        return <Route key={config.path} path={config.path} element={<PublicRoute {...config} />} />;
      case 'restricted':
        return <Route key={config.path} path={config.path} element={<RestrictedRoute {...config} />} />;
      default:
        return (
          <Route
            key={config.path}
            path={config.path}
            element={<PrivateRoute component={Component} isStyle={config.isStyle} />}
          />
        );
    }
  };

  const renderRoute = (config: RoutesConfigType): JSX.Element | undefined => {
    return renderLayout(config);
  };

  const renderRedirectionRoutes = redirectionRoutes.map(({ route, redirectionRoute }) => (
    <Route path={route} element={<Navigate to={route + redirectionRoute} replace />} />
  ));

  return (
    <Suspense fallback={<Loader />}>
      <Router>
        <Routes>
          {routesConfig.map(config => renderRoute(config))}
          <Route path='*' element={<RootRestrictedRoute />} />
          {renderRedirectionRoutes}
        </Routes>
      </Router>
    </Suspense>
  );
};

export default App;
