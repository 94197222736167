import apiEndpoints from '@src/lib/apiEndpoints';
import { FormControllerDataReturn } from '@src/lib/types';
import { User } from '@src/Models/user';

import apiRequest from './api';
import { addExtraPermissionsType, GetTableFilterProps } from './type';

export const getUsersApi = async (params: GetTableFilterProps): Promise<User[]> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.USERS}`,
    config: { params },
  });
};

export const getUserById = async (id: string, permission?: boolean): Promise<User> => {
  let urlExtension = '';

  if (permission) urlExtension += '?permissions=true';

  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.USERS}/${id}${urlExtension}`,
  });
};

export interface NickNameData {
  children: { student_id: string; nick_name: string };
}

export const updateNickname = async (id: string, data: NickNameData): Promise<User> => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.USERS}/${id}`,
    data,
  });
};

export const updateUser = async ({ data, id }: { id: string; data: FormControllerDataReturn }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.USERS}/${id}`,
    data: { ...data },
  });
};

export const createUser = async ({ data }: { data: FormControllerDataReturn }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.USERS}`,
    data: { ...data },
  });
};

export const addExtraPermissions = ({ data }: { data: addExtraPermissionsType }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.ADDITIONAL_PERMISSIONS}`,
    data: { ...data },
  });
};

export const updateExtraPermissions = ({ data }: { data: addExtraPermissionsType }) => {
  const { allowed_to, user_id } = data;
  return apiRequest({
    method: 'put',
    url: `${apiEndpoints.ADDITIONAL_PERMISSIONS}/${user_id}`,
    data: { allowed_to },
  });
};
export const switchUserRole = (data: { role: string }) => {
  return apiRequest({
    method: 'post',
    url: `${apiEndpoints.SWITCH_USER}`,
    data: { ...data },
  });
};
