/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, ReactNode, useContext, useEffect, useState } from 'react';

import { useAxiosApi } from '@src/hooks/useAxiosApi';
import { Routes } from '@src/lib/constants';
import { getHeadersWithPermissions } from '@src/lib/helper';
import { User } from '@src/Models/user';
import { deleteCookies } from '@src/utils/deleteCookies';
import { getCookie } from '@src/utils/getCookie';
import { setCookie } from '@src/utils/setCookie';

import { GlobalUIContext } from './GlobalUIContext';
import { LoginResponse } from './type';

export type HeadersType = Record<string, Record<string, string>> | 'super_admin' | null;

interface AuthContextProps {
  accessToken: string;
  userDetail?: User;
  removeAuthToken: () => Promise<void>;
  handleSetUserDetails: (token?: string) => Promise<void>;
  headers: HeadersType;
  setAuthToken: ({
    access_token,
    access_token_expires_in,
    refresh_token,
    refresh_token_expires_in,
    remember_me,
  }: LoginResponse) => void;
}

// Initialize externalSetUserDetails function
let externalSetUserDetails: (userDetail: { data: User }) => void = () => {};

export const AuthContext = createContext<AuthContextProps>({
  accessToken: '',
  headers: null,
  removeAuthToken: async () => {},
  setAuthToken: () => {},
  handleSetUserDetails: async () => {},
});

export const AuthContextProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string>('');
  const [userDetail, setUserDetail] = useState<User>();
  const [headers, setHeaders] = useState<HeadersType>(null);
  const { setLoading } = useContext(GlobalUIContext);
  const { handleGetUserDetails, handleUserLogout } = useAxiosApi();
  // const navigate = useNavigate();

  // Assign setUserDetail to externalSetUserDetails
  externalSetUserDetails = ({ data: userData }: { data: User }) => {
    try {
      if (userData) {
        setUserDetail(userData);

        if (userData.role === 'super_admin') {
          setHeaders('super_admin');
        } else {
          const headersWithPermissions = getHeadersWithPermissions(
            userData?.permissions,
            userData?.additional_permissions
          );

          setHeaders(headersWithPermissions);
        }
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  const removeAuthToken = async () => {
    try {
      await handleUserLogout();
    } catch (error) {
      console.log('Error', error);
    } finally {
      deleteCookies(['access_token', 'refresh_token', 'remember_me']);
      localStorage.removeItem('mode');
      setToken('');
      setUserDetail(undefined);
      window.location.href = Routes.LOGIN;
    }
  };

  useEffect(() => {
    const tokenValue = getCookie('access_token');
    const refreshToken = getCookie('refresh_token');

    if (tokenValue && token !== tokenValue) {
      setToken(tokenValue);
      handleSetUserDetails(tokenValue);
    } else if (refreshToken) {
      handleSetUserDetails();
    }

    setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setAuthToken = async ({
    access_token,
    access_token_expires_in,
    refresh_token,
    refresh_token_expires_in,
    remember_me,
  }: LoginResponse) => {
    await handleSetUserDetails(access_token);

    if (remember_me) {
      setCookie('remember_me', 'true', 86400);
    }

    setCookie('access_token', access_token, access_token_expires_in);
    refresh_token && setCookie('refresh_token', refresh_token, refresh_token_expires_in);
    setToken(access_token);
  };

  const handleSetUserDetails = async (access_token?: string) => {
    try {
      const res = await handleGetUserDetails(access_token);
      const userData = { ...res.data, '2fa': res['2fa'] };
      setUserDetail(userData);

      if (res.data.role === 'super_admin') {
        setHeaders('super_admin');
      } else {
        const headersWithPermissions = getHeadersWithPermissions(res.data.permissions, res.data.additional_permissions);
        setHeaders(headersWithPermissions);
      }
    } catch (error) {
      console.log('Error', error);
    }
  };

  return (
    <AuthContext.Provider
      value={{
        accessToken: token,
        userDetail,
        headers,
        removeAuthToken,
        setAuthToken,
        handleSetUserDetails,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const setUserDetailExternally = (userDetail: { data: User }) => {
  externalSetUserDetails(userDetail);
};
