import { DefaultTheme } from 'styled-components';

import { createTheme, Theme } from '@mui/material/styles';

import getTheme, { Modes, ModeType } from './theme';

declare module '@mui/material/styles' {
  interface Theme {
    styledTheme: DefaultTheme;
  }
  interface ThemeOptions {
    styledTheme?: DefaultTheme;
  }
}

const muiTheme = (styledTheme: DefaultTheme) =>
  createTheme({
    styledTheme,
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    components: {
      MuiTable: {
        styleOverrides: {
          root: {
            thead: {
              tr: {
                th: {
                  backgroundColor: styledTheme.colors.platinum,
                  fontWeight: 700,
                  padding: `${styledTheme.space.get('xs')} ${styledTheme.space.get('m')}`,
                },
              },
            },
          },
        },
      },
      MUIDataTableHeadCell: {
        styleOverrides: {
          data: {
            fontWeight: 700,
            whiteSpace: 'nowrap',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            whiteSpace: 'nowrap',
            padding: `${styledTheme.space.get('xs')} ${styledTheme.space.get('m')}`,
          },
        },
      },
    },
  });

const darkMuiTheme = (styledTheme: DefaultTheme) =>
  createTheme({
    styledTheme,
    typography: {
      fontFamily: 'Inter, sans-serif',
    },
    palette: {
      mode: 'dark',
      background: {
        paper: styledTheme.colors.navyBlue,
      },
    },
    components: {
      MUIDataTableHeadCell: {
        styleOverrides: {
          root: {
            fontWeight: 700,
          },
          data: {
            fontWeight: 700,
            whiteSpace: 'nowrap',
          },
        },
      },
      MUIDataTable: {
        styleOverrides: {
          root: {
            width: '100%',
          },
        },
      },
      MUIDataTableBodyRow: {
        styleOverrides: {
          root: {
            cursor: 'pointer',
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            padding: `${styledTheme.space.get('2xs')} ${styledTheme.space.get('m')}`,
          },
        },
      },
    },
  });

const getMuiTheme = (mode: ModeType): Theme => {
  const styledTheme = getTheme(mode);
  return mode === Modes.LIGHT ? muiTheme(styledTheme) : darkMuiTheme(styledTheme);
};

export { getMuiTheme };
