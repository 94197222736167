import { PropsWithChildren, useContext, useEffect } from 'react';

import { useMediaQuery } from '@mui/material';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';
import { useAxiosApi } from '@src/hooks/useAxiosApi';

import WebHeader from '../ParentMainLayout/WebHeader';

import MainHeader from './MainHeader';
import MapSubHeader from './MapSubHeader';
import MerchandiseSubHeader from './MerchandiseSubHeader';
import { StyleChildContainer, StyledMainContainer, StyledParentChildWrapper } from './styles';
import SubHeaders from './SubHeaders';

const MainLayout = ({
  children,
  isBreadcrumb,
  hasMerchandiseSubHeader,
}: { isBreadcrumb?: boolean; hasMerchandiseSubHeader?: boolean } & PropsWithChildren) => {
  const isDesktop = useMediaQuery('(min-width: 1024px)');
  const { handleGetCartProductCount } = useAxiosApi();
  const { userDetail } = useContext(AuthContext);
  const { setWalkThrough, setCartInfo } = useContext(GlobalUIContext);
  const { role = '' } = userDetail || {};
  const isParentLayout = role && ['parent', 'student'].includes(role);

  useEffect(() => {
    if (userDetail?.tuts && setWalkThrough) {
      for (const [phase, value] of Object.entries(userDetail.tuts)) {
        if (value) {
          setWalkThrough(prev => ({ ...prev, phase: Number(phase.slice(-1)) }));
          break;
        }
      }
    }
  }, [userDetail?.tuts, setWalkThrough]);

  const fetchCartProductCount = async () => {
    if (!(setCartInfo && hasMerchandiseSubHeader)) return;

    try {
      const res = await handleGetCartProductCount();
      setCartInfo(() => ({ count: res.count, refetch: fetchCartProductCount }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    fetchCartProductCount();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setCartInfo]);

  return (
    <StyledMainContainer>
      {!isParentLayout ? <MainHeader /> : null}
      {isDesktop && isParentLayout ? <WebHeader /> : null}

      {isBreadcrumb && !isParentLayout ? (
        <>
          {isDesktop ? <SubHeaders /> : null}
          <MapSubHeader />
        </>
      ) : null}
      {hasMerchandiseSubHeader ? <MerchandiseSubHeader /> : null}
      {isParentLayout ? (
        <StyledParentChildWrapper>{children}</StyledParentChildWrapper>
      ) : (
        <StyleChildContainer $isBreadcrumbOff={!isBreadcrumb}>{children}</StyleChildContainer>
      )}
    </StyledMainContainer>
  );
};

export default MainLayout;
