import { styled } from '@mui/material';

// Styled components for the modal
export const DrawerContent = styled('div')({
  padding: '0 16px 24px 16px',
  display: 'flex',
  flexDirection: 'column',
  height: '100%', // Make sure the div takes full height
});

export const Header = styled('div')(({theme})=>({
  padding: '24px 0 16px 0',
  color: theme?.styledTheme.colors.midnightGray,
  fontSize: '18px',
  fontWeight: '700',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  position: 'sticky',
  top: 0,
  backgroundColor: theme?.styledTheme.colors.offWhite,
  zIndex: 1,
}));

export const ScrollableContent = styled('div')({
  flex: 1, // Take up remaining space
  overflowY: 'auto', // Enable scrolling
});
