export type Monochrome = 'black' | 'white';
export type PrimaryPalette = 'iconColors' | 'textColors' | 'bgColors' | 'linearColors';

export interface RawColorPalette {
  indigo: string;
  carrotOrange: string;
  limeGreen: string;
  redOrange: string;
  navyBlue: string;
  cinnabar: string;
  appleGreen: string;
  midnightBlue: string;
  lightGray: string;
  amber: string;
  brightCyan: string;
  alabaster: string;
  platinum: string;
  spanishGray: string;
  babyBlue: string;
  royalBlue: string;
  persianIndigo: string;
  lightViolet: string;
  lavenderBlue: string;
  lavenderMist: string;
  orange: string;
  babyPurple: string;
  lightAqua: string;
  lightGreen: string;
  lightBlue: string;
  skyBlue: string;
  ashGray: string;
  sunglow : string;
  brightBlue: string;
  deepBlue: string;
  paleGray: string;
  shadeGray: string;
  offWhite: string;
  jetBlack: string;
  blazeOrange: string;
  blueBell: string;
  inkShade: string;
  dimGray: string;
  darkGray: string;
  gray: string;
  greenSuccess: string;
  orangeDark: string;
  mistGray: string;
  black: string;
  white: string;
  lightGrayBg: string;
  mediumGrayText: string;
  lightGreenBg: string;
  darkGreenText: string;
  lightOrangeBg: string;
  brightOrangeText: string;
  lightBlueBg: string;
  darkBlueText: string;
  mediumGray: string;
  darkRed: string;
  lightPeach: string;
  coolGrayText: string;
  deepGrayBorder: string;
  coolDeepGrayText: string;
  slateGray: string;
  charcoalMist: string;
  midnightGray: string;
  ivoryLace: string;
  ebony: string;
  verdant: string;
  powderBlue: string;
  apricot: string;
  tangerine: string;
  ochre: string;
  autumnLeaf: string;
  ivoryBlush: string;
  mintGreen: string;
  cobaltBlue: string;
  marigold: string;
  softGray: string;
  faintGray: string;
  fieryOrange: string;
  doveGray: string;
  electricBlue: string;
  charcoalGray: string;
  vanilla: string;
  icyBlue: string;
  midnightPurple: string;
  blushWhite: string;
  softSilver: string;
  iceBlue: string;
  ivoryCream: string;
  lightSteel: string;
  cantaloupe: string;
  gunmental: string;
  dandelion: string;
  silverMist: string;
  onyx: string;
  linearColors: {
    mangoTango: string;
    indigoDye: string;
    aliceBlue: string;
    oceanBlue: string;
  };
}

export const lightModeColors: RawColorPalette = {
  indigo: '#5157AB',
  coolDeepGrayText: '#999999',
  coolGrayText: '#828282',
  deepGrayBorder: '#D9D9D9',
  carrotOrange: '#EE8629',
  limeGreen: '#66CF35',
  redOrange: '#D33B2B',
  navyBlue: '#2e3192',
  cinnabar: '#F24423',
  appleGreen: '#55AE22',
  midnightBlue: '#12133A',
  lightGray: '#D3D3D3',
  amber: '#ff9900',
  brightCyan: '#00a3cc',
  alabaster: '#F4F6F6',
  platinum: '#ECECEC',
  spanishGray: '#8A8A8A',
  babyBlue: '#e7f1fe',
  royalBlue: '#2e3192',
  persianIndigo: '#300073',
  lightViolet: '#9ea0e0',
  lavenderBlue: '#ffffff',
  lavenderMist: '#4d94ff',
  orange: '#D97415',
  babyPurple: '#bebfe0',
  sunglow : '#FDB813',
  lightAqua: '#f0f5f5',
  lightGreen: '#90EE90',
  lightBlue: '#add8e6',
  skyBlue: '#99ccff',
  ashGray: '#cccccc',
  brightBlue: '#007bff',
  deepBlue: '#0056b3',
  paleGray: '#f2f2f2',
  shadeGray: '#f1f1f1',
  offWhite: '#FAFAFA',
  linearColors: {
    mangoTango: 'linear-gradient(#F8A960, #F08D30)',
    indigoDye: 'linear-gradient(#585DAF, #333B92)',
    aliceBlue: 'linear-gradient(#d2e4fe, #ffffff)',
    oceanBlue: 'linear-gradient(to bottom, #a4ccf4 10%, #d1e6fa 60%)',
  },
  ivoryLace: '#FEF8F2',
  ebony: '#1e1e1e',
  charcoalMist: '#707070',
  dandelion: '#FFCF00',
  mintGreen: '#33cf4d',
  mediumGray: '#757575',
  softGray: '#e0e0e0',
  faintGray: '#f9f9f9',
  blushWhite: '#FFF8F5',
  icyBlue: '#F2F3FF',
  fieryOrange: '#C74800',
  vanilla: '#fffaea',
  doveGray: '#888888',
  midnightPurple: '#16023F',
  electricBlue: '#4B5AFB',
  softSilver: '#DDDDDD',
  charcoalGray: '#292d32',
  midnightGray: '#3D3D3D',
  powderBlue: '#eaeaf0',
  onyx: '#39393D',
  silverMist: '#E9E9EA',
  lightSteel: '#E0E1E3',
  ivoryCream: '#FFF7EC',
  verdant: '#188142',
  gunmental: '#303030',
  cobaltBlue: '#1976d2',
  autumnLeaf: '#D9741535',
  ivoryBlush: '#FCF0E4',
  ochre: '#AF6118',
  iceBlue: '#E9F0FF',
  cantaloupe: '#FAE4CF',
  tangerine: '#E87F1C',
  marigold: '#FDB813',
  apricot: '#F5C395',
  greenSuccess: '#128A33',
  orangeDark: '#8A5312',
  jetBlack: '#333333',
  blazeOrange: '#EE8629',
  blueBell: '#5157AB',
  inkShade: '#000000',
  dimGray: '#666666',
  darkGray: '	#A9A9A9',
  gray: '#777777',
  mistGray: '#EEEEEE',
  black: '#1A1A1A',
  white: '#FFFFFF',
  lightGrayBg: '#F3F4F6',
  mediumGrayText: '#6B7280',
  lightGreenBg: '#CDF4DD',
  darkGreenText: '#188544',
  lightOrangeBg: '#FFECE1',
  brightOrangeText: '#FF5C00',
  lightBlueBg: '#DEE8FF',
  darkBlueText: '#5C69F8',
  darkRed: '#d32f2f',
  lightPeach: '#F1D9CA',
  slateGray: '#6F6C90',
};

export const darkModeColors: RawColorPalette = {
  indigo: '#5157AB',
  coolDeepGrayText: '#999999',
  charcoalMist: '#707070',
  ivoryLace: '#FEF8F2',
  midnightGray: '#3D3D3D',
  ebony: '#1e1e1e',
  mintGreen: '#33cf4d',
  cantaloupe: '#FAE4CF',
  ochre: '#AF6118',
  marigold: '#FDB813',
  powderBlue: '#eaeaf0',
  autumnLeaf: '#D9741535',
  lightSteel: '#E0E1E3',
  dandelion: '#FFCF00',
  ivoryCream: '#FFF7EC',
  onyx: '#39393D',
  verdant: '#188142',
  gunmental: '#303030',
  cobaltBlue: '#1976d2',
  ivoryBlush: '#FCF0E4',
  iceBlue: '#E9F0FF',
  silverMist: '#E9E9EA',
  softGray: '#e0e0e0',
  faintGray: '#f9f9f9',
  fieryOrange: '#C74800',
  doveGray: '#888888',
  charcoalGray: '#292d32',
  midnightPurple: '#16023F',
  vanilla: '#fffaea',
  blushWhite: '#FFF8F5',
  softSilver: '#DDDDDD',
  icyBlue: '#F2F3FF',
  electricBlue: '#4B5AFB',
  tangerine: '#E87F1C',
  apricot: '#F5C395',
  coolGrayText: '#828282',
  deepGrayBorder: '#D9D9D9',
  carrotOrange: '#EE8629',
  limeGreen: '#66CF35',
  redOrange: '#D33B2B',
  navyBlue: '#2e3192',
  cinnabar: '#F24423',
  appleGreen: '#55AE22',
  midnightBlue: '#12133A',
  lightGray: '#D3D3D3',
  amber: '#ff9900',
  brightCyan: '#00a3cc',
  alabaster: '#F4F6F6',
  platinum: '#ECECEC',
  spanishGray: '#8A8A8A',
  babyBlue: '#e7f1fe',
  royalBlue: '#2e3192',
  sunglow : '#FDB813',
  persianIndigo: '#300073',
  lightViolet: '#9ea0e0',
  lavenderBlue: '#8b8dda',
  lavenderMist: '#d8d9f3',
  orange: '#D97415',
  babyPurple: '#bebfe0',
  lightAqua: '#f0f5f5',
  lightGreen: '#90EE90',
  lightBlue: '#5155c8',
  skyBlue: '#99ccff',
  ashGray: '#cccccc',
  brightBlue: '#007bff',
  deepBlue: '#0056b3',
  paleGray: '#f2f2f2',
  shadeGray: '#f1f1f1',
  offWhite: '#FAFAFA',
  linearColors: {
    mangoTango: 'linear-gradient(#F8A960, #F08D30)',
    indigoDye: 'linear-gradient(#585DAF, #333B92)',
    aliceBlue: 'linear-gradient(#d2e4fe, #ffffff)',
    oceanBlue: 'linear-gradient(to bottom, #a4ccf4 10%, #d1e6fa 60%)',
  },
  greenSuccess: '#128A33',
  orangeDark: '#8A5312',
  jetBlack: '#333333',
  blazeOrange: '#EE8629',
  blueBell: '#5157AB',
  inkShade: '#FFFFFF',
  dimGray: '#666666',
  darkGray: '	#A9A9A9',
  gray: '#777777',
  mistGray: '#EEEEEE',
  black: '#1A1A1A',
  white: '#FFFFFF',
  lightGrayBg: '#F3F4F6',
  mediumGrayText: '#6B7280',
  lightGreenBg: '#CDF4DD',
  darkGreenText: '#188544',
  lightOrangeBg: '#FFECE1',
  brightOrangeText: '#FF5C00',
  lightBlueBg: '#DEE8FF',
  darkBlueText: '#5C69F8',
  mediumGray: '#757575',
  darkRed: '#d32f2f',
  lightPeach: '#F1D9CA',
  slateGray: '#6F6C90',
};

export type AllColorPalette = RawColorPalette;
