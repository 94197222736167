import styled from 'styled-components';

import { Box, Typography } from '@mui/material';

export const StyledContainer = styled(Box)(({ theme }) => ({
  padding: '12px',
  background: theme.colors.white,
  borderRadius: '8px',
  width: '250px',
  display: 'flex',
  flexDirection: 'column',
  gap: '8px',
}));

export const StyledHeading = styled(Typography)(({ theme }) => ({
  color: theme.colors.jetBlack,
  fontWeight: '600',
  fontSize: '14px',
}));

export const StyledContent = styled(Typography)(({ theme }) => ({
  color: theme.colors.charcoalMist,
  fontWeight: '500',
  fontSize: '12px',
}));

export const StyledFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const StyledButton = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: '7px',
  borderRadius: '8px',
  border: `1px solid ${theme.colors.deepGrayBorder}`,
  cursor: 'pointer',
}));

export const StyledPrimaryButton = styled(StyledButton)(({ theme }) => ({
  backgroundColor: theme.colors.jetBlack,
  color: theme.colors.white,
}));
