import apiEndpoints from '@src/lib/apiEndpoints';
import { Product } from '@src/Models/product';
import { ResponseData } from '@src/screens/Staff/type';

import apiRequest from './api';
import { AddToCartProps, GetTableFilterProps } from './type';

export const softDeleteProducts = async ({ data }: { data: { id: string; active: boolean } }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PRODUCT_DELETE}`,
    data: { ...data },
  });
};

export const getProducts = async (params: GetTableFilterProps): Promise<ResponseData<Product>> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PRODUCTS}`,
    config: { params },
  });
};

export const createProduct = (data: Product) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.PRODUCTS}`,
    data,
  });
};

export const editProduct = (id: string, data: Product) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.PRODUCTS}/${id}`,
    data,
  });
};

export const getProductById = async (id: string): Promise<{ data: Product }> => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.PRODUCTS}/${id}`,
  });
};

export const addToCart = (data: AddToCartProps) => {
  return apiRequest({
    method: 'POST',
    url: `${apiEndpoints.CART}`,
    data,
  });
};

export const getCartDetails = async (params: GetTableFilterProps) => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CART}`,
    config: { params },
  });
};

export const getCartProductCount = async () => {
  return apiRequest({
    method: 'GET',
    url: `${apiEndpoints.CART_COUNT}`,
  });
};

export const deleteCartProduct = async (id: string) => {
  return apiRequest({
    method: 'DELETE',
    url: `${apiEndpoints.CART}/${id}`,
  });
};

export const editProductQuantityInCart = (id: string, data: { quantity: number }) => {
  return apiRequest({
    method: 'PUT',
    url: `${apiEndpoints.CART}/${id}`,
    data,
  });
};
