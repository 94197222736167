import { PropsWithChildren, useContext, useEffect } from 'react';
import ReactJoyride, { Step } from 'react-joyride';

import { AuthContext } from '@src/contexts/AuthContextProvider';
import { GlobalUIContext } from '@src/contexts/GlobalUIContext';

import CustomToolTip from './CustomToolTip';

export interface StepsProps extends Step {
  heading: string;
}

interface WalkThroughProps extends PropsWithChildren {
  steps: StepsProps[];
  phase: number;
  isSkip?: boolean;
}

const WalkThrough = ({ children, steps, phase, isSkip }: WalkThroughProps) => {
  const { userDetail } = useContext(AuthContext);
  const { walkThrough, setWalkThrough } = useContext(GlobalUIContext);

  const isPhaseSame = userDetail?.tuts && userDetail.tuts[`phase${phase}`] && (isSkip || walkThrough.phase === phase);

  useEffect(() => {
    if (setWalkThrough && isPhaseSame && !walkThrough.tourRunning) {
      setWalkThrough(prev => ({ ...prev, tourRunning: true }));
    }
  }, [setWalkThrough, walkThrough.phase, walkThrough.tourRunning, isPhaseSame]);

  return (
    <>
      {isPhaseSame ? (
        <ReactJoyride
          steps={steps}
          run={walkThrough.tourRunning}
          stepIndex={walkThrough.stepIndex}
          continuous
          scrollToFirstStep
          disableOverlayClose
          showSkipButton={false}
          tooltipComponent={data => CustomToolTip({ ...data, phase, isSkip })}
          styles={{
            options: {
              backgroundColor: '#e3ffeb',
              zIndex: 99999,
            },
          }}
        />
      ) : null}
      {children}
    </>
  );
};

export default WalkThrough;
