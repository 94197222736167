import { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`
body {
  font-family: ${props => props.theme.fonts.default};
  color: ${props => props.theme.colors.black};
}

html, body, #root {
  height: 100%;
  min-height: 100vh;
  max-height: 100vh;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

h1, h2, h3, h4, h5, h5, h6, p {
  margin: 0;
  padding: 0;
}

 &::-webkit-scrollbar {
    width: 4px;
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(100, 100, 100, 0.5);
    border-radius: 20px;
    background-clip: content-box;
    }
`;
