import styledComponents from 'styled-components';

import { alpha, Button, InputBase, styled } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { ModeType } from '@src/lib/styles/theme';

export const Search = styled('div')<{ $mode: ModeType }>(({ theme, $mode }) => {
  const { styledTheme } = useGetTheme();

  return {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: $mode === 'light' ? styledTheme.colors.lightAqua : alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: $mode === 'light' ? styledTheme.colors.lightAqua : alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  };
});

export const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

export const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  width: '100%',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
  },
}));

export const StyledToolbarContainer = styled('div')(({ theme }) => {
  const { styledTheme } = useGetTheme();

  return {
    display: 'flex',
    justifyContent: 'flex-end',
    gap: styledTheme.space.get('s'),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      marginTop: 10,
      gap: 0,
    },
  };
});

export const VisuallyHiddenInput = styled('input')({
  clip: 'rect(0 0 0 0)',
  clipPath: 'inset(50%)',
  height: 1,
  overflow: 'hidden',
  position: 'absolute',
  bottom: 0,
  left: 0,
  whiteSpace: 'nowrap',
  width: 1,
});

export const StyledIconsContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'flex-end',
}));

export const StyledButton = styledComponents(Button)`
background-color: ${({ theme }) => theme.colors.orange};
color: ${({ theme }) => theme.colors.white};
padding-left: ${({ theme }) => theme.space.get('m')};
padding-right: ${({ theme }) => theme.space.get('m')};
text-transform: none;
margin: 0 ${({ theme }) => theme.space.get('2xs')};

 &:hover {
    background-color: ${({ theme }) => theme.colors.orange};
  }
`;
