import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useSearchParams } from 'react-router-dom';

import { Box, Dialog, DialogContent, Typography } from '@mui/material';

import ProfileDrawerLayout from '@src/components/ParentMainLayout/MobileHeader/NewSidebar/ProfileDrawerLayout';
import { AuthContext } from '@src/contexts/AuthContextProvider';
import { useGetTheme } from '@src/hooks/useGetTheme';
import { Children, StudentId } from '@src/Models/user';

const ProfileModal = () => {
  const navigate = useNavigate();
  const { userDetail } = useContext(AuthContext);
  const selectedProfileId = useSearchParams()[0].get('selectedProfileId');
  const { t } = useTranslation();
  const { styledTheme } = useGetTheme();
  let childData: Children | StudentId | undefined = userDetail?.children?.find(item => item.id === selectedProfileId);
  const isStudent = userDetail?.role === 'student';

  if (isStudent) {
    childData = userDetail?.student_id;
  }

  const handleClose = () => {
    const currentPath = window.location.pathname;
    navigate(currentPath);
  };

  if (!childData) return null;

  return (
    <Dialog
      onClick={e => e.stopPropagation()}
      open={!!selectedProfileId}
      onClose={handleClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
      PaperProps={{
        sx: { borderRadius: '24px', minWidth: '500px' },
      }}
    >
      <DialogContent>
        <Box sx={{ display: 'flex', flexDirection: 'column', gap: '40px' }}>
          <Typography
            sx={{ fontSize: '18px', fontWeight: '700', color: styledTheme.colors.midnightGray, textAlign: 'center' }}
          >
            {t('editProfile')}
          </Typography>
          <ProfileDrawerLayout childData={childData} />
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default ProfileModal;
