import { RoutesConfigType } from '@src/lib/types';

import newAsaRoutes from './asaNewRoutes';
import asaRoutes from './asaRoutes';
import cafeRoutes from './cafeRoutes';
import gateRoutes from './gateRoutes';
import generalRoutes from './generalRoutes';
import homeRoutes from './homeRoutes';
import loginRoutes from './loginRoutes';
import logsRoute from './logsRoutes';
import merchandiseRoutes from './merchandiseRoutes';
import poRoutes from './poRoutes';
import reportsRoutes from './reportsRoutes';
import usersRoutes from './usersRoutes';
import walletRoutes from './walletRoutes';

const routesConfig: Array<RoutesConfigType> = [
  ...homeRoutes,
  ...usersRoutes,
  ...newAsaRoutes,
  ...asaRoutes,
  ...poRoutes,
  ...logsRoute,
  ...loginRoutes,
  ...gateRoutes,
  ...cafeRoutes,
  ...walletRoutes,
  ...generalRoutes,
  ...merchandiseRoutes,
  ...reportsRoutes,
];

export default routesConfig;
