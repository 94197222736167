import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { CircularProgress, Tooltip } from '@mui/material';

import { GlobalUIContext } from '@src/contexts/GlobalUIContext';

import { StyledButton, StyledIconsContainer, StyledToolbarContainer } from '../style';
import { CustomTableToolbarProps } from '../type';

import { TableColumnFilterLayout } from './TableColumnFilterLayout';
import { TableCreateRowLayout } from './TableCreateRowLayout';
import TableDownloadSchemaLayout from './TableDownloadSchemaLayout';
import { TableFilterLayout } from './TableFilterLayout';
import { TableSearchLayout } from './TableSearchLayout';
import { TableSyncUserLayout } from './TableSyncUserLayout';
import { TableUploadButtonLayout } from './TableUploadButtonLayout';

export const CustomTableToolbar: React.FC<CustomTableToolbarProps> = data => {
  const { loading } = useContext(GlobalUIContext);
  const { handleUploadFile, addRowData, children, filterSchema, downloadSchemaLinks, isUserSynUp, ctaButton } = data;
  const { t } = useTranslation();

  return (
    <StyledToolbarContainer>
      {loading === 'table' ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CircularProgress size={24} />
        </div>
      ) : null}
      <TableSearchLayout {...data} />
      <StyledIconsContainer>
        {isUserSynUp ? <TableSyncUserLayout /> : null}
        {!!handleUploadFile ? <TableUploadButtonLayout {...data} /> : null}
        {downloadSchemaLinks ? <TableDownloadSchemaLayout {...data} /> : null}
        {filterSchema ? <TableFilterLayout {...data} /> : null}
        {!!addRowData ? <TableCreateRowLayout {...data}>{children}</TableCreateRowLayout> : null}
        {ctaButton ? (
          <Tooltip title={t(ctaButton.text)}>
            <StyledButton onClick={ctaButton.handleClick}>{t(ctaButton.text)}</StyledButton>
          </Tooltip>
        ) : null}
        <TableColumnFilterLayout {...data} />
      </StyledIconsContainer>
    </StyledToolbarContainer>
  );
};
