import styled from 'styled-components';

import { Close } from '@mui/icons-material';
import { Box, IconButton, ListItemButton } from '@mui/material';

import { Breakpoints } from '@src/lib/styles/breakpoints';
import { UserSelectNone } from '@src/lib/styles/globalWrapper';

export const HeaderContainer = styled.div`
  display: flex;
  max-height: 85px;
  padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('2xl')};
  justify-content: space-between;
  align-items: center;
  user-select: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  background: ${({ theme }) => theme.colors.white};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: 0 ${({ theme }) => theme.space.get('s')};
  }
`;

export const HeaderLeft = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.get('xs')};
`;

export const HeaderItem = styled.div<{ $isActive: boolean }>`
  padding: ${({ theme }) => theme.space.get('2xs')} ${({ theme }) => theme.space.get('s')};
  border-radius: 5px;
  font-weight: 600;
  cursor: pointer;
  font-size: ${({ theme }) => theme.fontSize.get('s')};
  white-space: nowrap;
  color: ${({ $isActive, theme }) => ($isActive ? theme?.colors.blazeOrange : theme.colors.coolDeepGrayText)};
  background: ${({ $isActive, theme }) => ($isActive ? `${theme?.colors.blazeOrange}35` : 'transparent')};
  transition: color 0.3s ease, background 0.3s ease;

  &:hover {
    color: ${({ theme }) => theme?.colors.blazeOrange};
    background: ${({ theme }) => `${theme?.colors.blazeOrange}35`};
  }
`;
export const HeaderRight = styled.div`
  display: flex;
  align-items: center;
  gap: ${({ theme }) => theme.space.get('s')};
`;

export const StyledLogo = styled.img`
  width: 99px;
  height: 28px;
  cursor: pointer;
  margin-right: ${({ theme }) => theme.space.get('s')};
`;

export const SubHeaderContainer = styled.div`
  display: flex;
  min-height: 68px;
  max-height: 68px;
  gap: ${({ theme }) => theme.space.get('l')};
  padding: 0 ${({ theme }) => theme.space.get('2xl')};
  align-items: center;
  background: ${({ theme }) => theme.colors.white};
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.deepGrayBorder};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('m')};
  }
`;

export const SubHeader = styled.div<{ $isActive: boolean }>`
  padding: calc(${({ theme }) => theme.space.get('m')} - 3px) 0;
  color: ${({ $isActive, theme }) => ($isActive ? theme.colors.black : theme.colors.coolDeepGrayText)};
  font-size: calc(${({ theme }) => theme.fontSize.get('s')} - 2px);
  font-weight: 600;
  cursor: pointer;
  box-sizing: border-box;
  border-bottom: ${({ $isActive }) => ($isActive ? '3px solid black' : '3px solid transparent')};
  transition: color 0.3s ease, border-bottom 0.3s ease;

  &:hover {
    color: black;
    border-bottom: 3px solid black;
  }
`;

export const StyledMainContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  max-height: 100vh;
  max-width: 100vw;
  overflow-x: hidden;
  background: ${({ theme }) => theme.colors.offWhite};
`;

export const StyleChildContainer = styled.div<{ $isBreadcrumbOff: boolean }>`
  display: flex;
  padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('2xl')};
  max-height: calc(100vh - ${({ $isBreadcrumbOff }) => ($isBreadcrumbOff ? '86px' : '213px')});
  overflow-x: hidden;
  overflow-y: auto;
  flex-direction: column;
  flex: auto;
  background: ${({ theme }) => theme.colors.offWhite};
  padding-top: 0;
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('m')};
    max-height: calc(100vh - ${({ $isBreadcrumbOff }) => ($isBreadcrumbOff ? '60px' : '110px')});
    padding-top: 0;
  }
`;

export const MapSubHeaderContainer = styled.div`
  display: flex;
  max-height: 60px;
  font-weight: 500;
  font-size: ${({ theme }) => theme.fontSize.get('xs')};
  padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('2xl')};
  align-items: center;
  box-sizing: border-box;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mistGray};
  background: ${({ theme }) => theme.colors.offWhite};
  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('xs')} ${({ theme }) => theme.space.get('m')};
    min-height: 50px;
  }
  & > div:last-child {
    color: ${({ theme }) => theme.colors.blueBell};
  }
`;

export const RouteMapItem = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export const DrawerHeader = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: '16px',
});

export const DrawerItemButton = styled(ListItemButton)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
  paddingLeft: '16px',
  '& > div': {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    '& > div': {
      display: 'flex',
      alignItems: 'center',
    },
  },
});

export const SubHeaderButton = styled(ListItemButton)`
  margin-left: ${({ theme }) => theme.space.get('l')};
`;

export const AllSubHeadersContainer = styled.div(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: 'repeat(auto-fit, minmax(300px, 1fr))',

  '@media (min-width: 890px)': {
    gridTemplateColumns: 'repeat(4, 1fr)',
  },
}));

export const StyledIconButton = styled(IconButton)`
  background-color: ${({ theme }) => theme.colors.offWhite};
`;

export const StyledCloseIcon = styled(Close)`
  font-size: ${({ theme }) => theme.fontSize.get('s')};
`;

export const DropdownContainer = styled.div`
  z-index: 1000;
  position: absolute;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.white};
  padding: ${({ theme }) => theme.space.get('m')} ${({ theme }) => theme.space.get('2xl')};

  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('m')};
  }
`;

export const MerchandiseSubHeaderContainer = styled.div`
  background: ${({ theme }) => theme.colors.cantaloupe};
  display: flex;
  justify-content: space-between;
  padding: 0 ${({ theme }) => theme.space.get('2xl')};

  ${Breakpoints.DESKTOP_SMALL} {
    padding: ${({ theme }) => theme.space.get('s')} ${({ theme }) => theme.space.get('m')};
  }
`;

export const MerchandiseCategoriesContainer = styled.div`
  display: flex;
  gap: ${({ theme }) => theme.space.get('l')};
`;

export const MerchandiseSubHeader = styled(UserSelectNone)`
  cursor: pointer;
  font-weight: 600;
  padding: calc(${({ theme }) => theme.space.get('s')} - 3px) 0;
  font-size: calc(${({ theme }) => theme.fontSize.get('s')} - 2px);
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
`;

export const DropdownSubHeader = styled(UserSelectNone)<{ $index: number }>`
  cursor: pointer;
  font-weight: 400;
  padding: ${({ theme }) => theme.space.get('2xs')} 0;
  font-size: calc(${({ theme }) => theme.fontSize.get('s')} - 2px);
  color: ${({ theme }) => theme.colors.black};
  display: flex;
  align-items: center;
  margin-right: ${({ theme }) => theme.space.get('m')};

  border-right: ${({ theme, $index }) => ($index % 4 ? `1px solid ${theme.colors.gray}` : 'none')};
  ${Breakpoints.TABLET} {
    border-right: ${({ theme, $index }) => ($index % 2 ? `1px solid ${theme.colors.gray}` : 'none')};
  }
  ${Breakpoints.TABLET_SMALL} {
    border-right: none;
  }
`;

export const StyledParentChildWrapper = styled(Box)(({theme}) => ({
  display: 'flex',
  flexDirection: 'column',
  padding: '0 60px',
  overflowX: 'hidden',
  overflowY: 'auto',
  backgroundColor: theme.colors.offWhite,
  flex: 'auto',
  maxHeight: 'calc(100vh - 64px)',
  [Breakpoints.DESKTOP_SMALL]: {
    padding: '0 16px',
    maxHeight: '100vh',
  },
}));
