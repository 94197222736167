import { useTranslation } from 'react-i18next';

import CloseIcon from '@mui/icons-material/Close';
import { Drawer, IconButton, useMediaQuery } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';
import { AccordionLayout } from '@src/screens/Dashboard/AccordionLayout';

import { DrawerContent, Header, ScrollableContent } from './style';

export const GeneralInfoModal = ({ handleClose, isOpen }: { handleClose: () => void; isOpen: boolean }) => {
  const isMobile = useMediaQuery('(max-width:650px)');
  const { t } = useTranslation();
  const {styledTheme} = useGetTheme();

  const toggleDrawer = (open: boolean) => (event: KeyboardEvent | MouseEvent) => {
    if (event.type === 'keydown' && ['Tab', 'Shift'].includes((event as KeyboardEvent).key)) {
      return;
    }

    if (!open) {
      handleClose();
    }
  };

  return (
    <Drawer
      anchor={isMobile ? 'bottom' : 'right'}
      open={isOpen}
      onClose={toggleDrawer(false)}
      sx={{
        '.MuiPaper-root': {
          maxWidth: '400px',
          width: '100%',
          background: styledTheme.colors.offWhite,
          ...(isMobile && { borderRadius: '30px 30px 0 0' }),
        },
      }}
    >
      <DrawerContent>
        <Header>
          {t('generalInfo')}
          <IconButton onClick={handleClose}>
            <CloseIcon sx={{ color: styledTheme.colors.ebony, width: '24px', height: '24px' }} />
          </IconButton>
        </Header>

        <ScrollableContent>
          <AccordionLayout />
        </ScrollableContent>
      </DrawerContent>
    </Drawer>
  );
};
