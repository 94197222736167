import { RefObject, useState } from 'react';

import { Box, IconButton, TextField, Typography } from '@mui/material';

import { useGetTheme } from '@src/hooks/useGetTheme';

const FormValueLayout = ({
  heading,
  value,
  optional,
  placeholder,
  calendar,
  instructions,
  inputRef,
  id,
}: {
  heading: string;
  id?: string;
  value?: string;
  calendar?: boolean;
  optional?: boolean;
  placeholder?: string;
  instructions?: string;
  inputRef?: RefObject<HTMLInputElement>;
}) => {
  const [edit, setEdit] = useState(false);
  const { styledTheme } = useGetTheme();
  return (
    <Box id={id} sx={{ display: 'flex', flexDirection: 'column', gap: '6px' }}>
      <Box sx={{ display: 'flex', gap: '6px', alignItems: 'baseline' }}>
        <Typography sx={{ fontSize: '14px', fontWeight: '600', color: styledTheme.colors.midnightGray }}>{heading}</Typography>
        {optional ? (
          <Typography sx={{ fontSize: '12px', fontWeight: '400', color: styledTheme.colors.charcoalMist }}>(Optional)</Typography>
        ) : null}
      </Box>
      <Box
        sx={{
          border: `1px solid ${styledTheme.colors.mistGray}`,
          p: '10px 14px',
          borderRadius: '8px',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <TextField
          variant='standard'
          inputRef={inputRef}
          defaultValue={value}
          fullWidth
          disabled={!inputRef || !edit}
          placeholder={placeholder}
          InputProps={{
            disableUnderline: true,
          }}
          sx={{
            '& .MuiInputBase-root': {
              fontSize: '14px',
              fontWeight: '400',
              color: styledTheme.colors.midnightGray,
              textTransform: 'capitalize',
            },
          }}
        />
        {inputRef && !edit ? (
          <IconButton
            onClick={() => {
              setEdit(true);
              inputRef.current?.focus();
            }}
          >
            <img src='/icons/edit-2.svg' alt='icon' style={{ cursor: 'pointer' }} autoFocus />
          </IconButton>
        ) : null}
        {calendar ? <img src='/icons/calendar-2.svg' alt='icon' /> : null}
      </Box>
      {instructions ? (
        <Typography sx={{ fontSize: '12px', fontWeight: '400', color: styledTheme.colors.charcoalMist }}>{instructions}</Typography>
      ) : null}
    </Box>
  );
};

export default FormValueLayout;
